import { ref } from 'vue'
import { useStore } from 'vuex'
import axios from 'axios'

export default function(PaymentSuccess) {
  const store = useStore()
  const loadIframe = ref(false)
  const fetching = ref(false)

  const IframeCallback = (trxref, retry) => {
    fetching.value = true
    store.dispatch('showIndicator')
    axios({
      method: 'get',
      url: '/payment/verify/' + trxref
    })
      .then((response) => {
        fetching.value = false

        if (response.data.status === 'success') {
          const payment = response.data.result.payment
          const transaction = response.data.result.Trx

          if (payment.status === 'success') {
            PaymentSuccess(response.data.result)
            if (transaction.apiCalled === 1 || retry === 3) {
              console.log(3);
              const addedMsg =
                transaction.apiCalled === 0
                  ? ' but was not applied. Please contact us with you reference number (' +
                    transaction.PaymentRef +
                    ') to update payment'
                  : ''
              const status = transaction.apiCalled === 1 ? 'success' : 'error'
              store.dispatch('showAlert', {
                message: '<h3>Transaction status</h3>Payment was successful' + addedMsg,
                status: status,
                showing: true
              })
              store.dispatch('hideIndicator')
            }
          } else {
            store.dispatch('showAlert', {
              message: response.data.result.message ? '<h3>Transaction status</h3>' + response.data.result.message : response.data.result,
              status: 'error',
              showing: true
            })
            store.dispatch('hideIndicator')
          }
        } else {
          store.dispatch('hideIndicator')
          store.dispatch('showAlert', {
            message: response.data.result.message ? '<h3>Transaction status</h3>' + response.data.result.message : response.data.result,
            status: 'error',
            showing: true
          })
        }
      })
      .finally(() => {
        fetching.value = false
        loadIframe.value = false
      })
  }
  const closeIframe = (paymentbox) => {
    document
      .getElementById(paymentbox)
      .contentWindow.postMessage('cancelFromClient', '*')
  }
  window.addEventListener(
    'message',
    (event) => {
      onMessage(event)
    },
    false
  )
  const onMessage = (event) => {
    // if (event.origin !== "http://example.com") return;
    switch (event.data.action) {
      case 'log':
        break
      case 'alert':
        store.dispatch('showAlert', {
          message: event.data.response,
          status: 'error',
          showing: true
        })
        break
      case 'processed':
      case 'closed':
      case 'cancelledFromClient':
        if (loadIframe.value) IframeCallback(event.data.trxref, 0)
        break
    }
  }

  return { loadIframe, closeIframe, IframeCallback, fetching }
}
