<template>
  <vue-highcharts
    type="chart"
    :options="chartOptions"
    :redrawOnUpdate="true"
    :oneToOneUpdate="false"
    :animateOnUpdate="true"
    @rendered="onRender"
  />
</template>
<script>
import VueHighcharts from "vue3-highcharts";
export default {
  components: { VueHighcharts },
  props: {
    seriesData: Array,
    colors: Array,
  },
  data() {
    return {
      title: "Chart",
    };
  },

  methods: {
    onRender() {
      this.$emit("equalColumns");
    },

    onUpdate() {
      this.$emit("equalColumns");
    },

    onDestroy() {},
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "pie",
          spacing: [0, 0, 0, 0],
          margin: [0, 0, 0, 0],
          padding: [0, 0, 0, 0],
          height: "100%", // 16:9 ratio
        },
        credits: {
          enabled: false,
        },
        exporting: false,
        title: {
          text: null,
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          title: false,
          reversed: false,
          visible: false,
        },
        yAxis: {
          visible: false,
        },
        plotOptions: {
          pie: {
            shadow: true,
          },
        },
        series: [
          {
            name: "Balance",
            data: this.seriesData,
            colors: this.colors,
            size: "100%",
            innerSize: "50%",
            dataLabels: {
              enabled: false,
            },
          },
        ],
      };
    },
  },
};
</script>
