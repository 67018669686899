<template>
  <div v-if="fetchingTrans" class="loading_spinner_box">
    <div class="loading_spinner"></div>
  </div>
  <section role="main" class="content-body">
    <header class="page-header">
      <h2>{{ title }}</h2>
    </header>

    <div class="p-4">
      <div class="row">
        <div class="col-md-10 w3-border-right">
          <div class="row">
            <div class="col-md-8">
              <h4 class="mb-1">{{ greeting() }}</h4>
              <h3 class="mt-0 mb-0 w3-text-black text-shadow">
                {{ fullname }}
              </h3>
              <div class="mb-4 size15">
                Account # <span class="size18 bold">{{ contactNo }}</span>
              </div>
            </div>
          </div>
          <SummaryCard />
          <div class="row equalHeightColumns">
            <div class="col-xl-4 col-lg-5 col-md-5 col-sm-6">
              <PieChartBox @equalColumns="equalColumns" />
            </div>
            <div class="col-xl-8 col-lg-7 col-md-7 col-sm-6">
              <PriceChartBox @equalColumns="equalColumns" />
            </div>
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
  </section>
</template>
<script>
import { useStore } from "vuex";
import { computed, ref, onMounted, onBeforeMount } from "vue";
import { jquery } from "@/mixins/jquery";
import SummaryCard from "@/components/SummaryCard";
import PieChartBox from "@/components/PieChartBox";
import PriceChartBox from "@/components/priceChartBox";
import { equalHeightColumns, queryParameter } from "@/helpers/utilities";
import usePayments from "@/composables/use-payments";
export default {
  components: {
    SummaryCard,
    PieChartBox,
    PriceChartBox,
  },
  mixins: [jquery],
  setup() {
    const store = useStore();
    const title = ref("Dashboard");

    const userData = ref(store.state.userData);
    const contactNo = computed(() => userData.value.contactNo);
    const fullname = computed(() => userData.value.Account_Name);

    const greeting = () => {
      var hour = new Date().getHours();
      if (hour < 12) {
        return "Good morning!";
      }
      if (hour > 12) {
        return "Good afternoon!";
      }
      if (hour > 18) {
        return "Good evening!";
      }
    };
    const equalColumns = () => {
      equalHeightColumns();
    };

    const retry = ref(1);
    const PaymentSuccess = (data) => {
      if (data.Trx.apiCalled !== 1) {
        if (retry.value <= 3) {
          retry.value++;
          Callback(trxref, retry.value);
        }
      }
    };
    const { IframeCallback: Callback, fetching: fetchingTrans } = usePayments(
      PaymentSuccess
    );
    const trxref = queryParameter().trxref;
    onBeforeMount(() => {
      if (trxref) {
        Callback(trxref, retry.value);
      }

      store.dispatch("resetIdleTime");
    });

    onMounted(() => {
      equalHeightColumns();
    });
    return {
      title,
      contactNo,
      fullname,
      greeting,
      equalColumns,
      fetchingTrans,
    };
  },
};
</script>
