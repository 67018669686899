<template>
  <vue-highcharts
    type="chart"
    :options="chartOptions"
    :redrawOnUpdate="true"
    :oneToOneUpdate="false"
    :animateOnUpdate="true"
    @rendered="onRender"
  />
</template>
<script>
import VueHighcharts from "vue3-highcharts";

export default {
  components: { VueHighcharts },
  props: {
    seriesData: Array,
    colors: Array,
    categories: Array,
    chatTitle: String,
  },
  data() {
    return {
      title: "Chart",
    };
  },

  methods: {
    onRender() {
      this.$emit("equalColumns");
    },

    onUpdate() {
      this.$emit("equalColumns");
    },

    onDestroy() {},
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "spline",
          height: 300,
          // margin: [20, 0, 0, 0],
          // padding: [0, 0, 0, 0],
          // height: (9 / 25) * 100 + "%", // 16:9 ratio
        },
        credits: {
          enabled: false,
        },
        exporting: false,
        title: {
          text: this.chatTitle,
          align: "center",
        },
        yAxis: {
          title: {
            text: "Price (&#8358;)",
          },
        },

        xAxis: {
          labels: {
            enabled: true,
          },
          categories: this.categories,
        },
        legend: {
          margin: 40,
        },

        // plotOptions: {
        //   series: {
        //     label: {
        //       connectorAllowed: true,
        //     },
        //   },
        // },

        series: this.seriesData,
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                legend: {
                  margin: 30,
                },
              },
            },
          ],
        },
      };
    },
  },
};
</script>
