<template>
  <header class="header">
    <div class="logo-container">
      <a href="/" class="logo mr-2">
        <img src="base/logo.png" height="45" :alt="appTitle" />
      </a>
      <div
        class="d-md-none toggle-sidebar-left"
        data-toggle-class="sidebar-left-opened"
        data-target="html"
        data-fire-event="sidebar-left-opened"
      >
        <i class="fas fa-bars" aria-label="Toggle sidebar"></i>
      </div>
    </div>
    <div v-show="loggedIn" class="header-right">
      <div id="userbox" class="userbox">
        <a href="#" data-toggle="dropdown">
          <figure class="profile-picture">
            <img
              v-if="userData.picture"
              :src="userData.picture"
              :alt="fullname"
              class="rounded-circle"
            />
            <img
              v-else
              src="base/img/LClogo.jpg"
              style="width: 36px"
              :alt="fullname"
              class="rounded-circle"
            />
          </figure>
          <div class="profile-info">
            <span class="name">{{ fullname }}</span>
            <span class="role">{{ ContactNo }}</span>
          </div>

          <i class="fa custom-caret"></i>
        </a>

        <div class="dropdown-menu">
          <ul class="list-unstyled mb-2">
            <li class="divider"></li>
            <li>
              <router-link
                role="menuitem"
                tabindex="-1"
                :to="{ name: 'profile' }"
                ><i class="bx bx-user-circle"></i> My Profile</router-link
              >
            </li>
            <li>
              <a
                role="menuitem"
                tabindex="-1"
                href="#"
                @click.prevent="lockScreen"
                ><i class="bx bx-lock"></i> Lock Screen</a
              >
            </li>
            <li>
              <a href="#" @click.prevent="logout" role="menuitem" tabindex="-1"
                ><i class="bx bx-power-off"></i> Log out</a
              >
            </li>

            <li v-if="RM" class="investmentSummary">
              <div
                v-for="rm in RM"
                @click="ShowRM(rm)"
                :key="rm.RmEmail"
                class="size14 mx-3 p-3 cursor w3-hover-border-red overflow-hidden"
              >
                <span class="w3-block bold w3-text-orange"
                  >ACCOUNT MANAGER</span
                >
                <span class="w3-block w3-text-white"
                  ><span class="size12 w3-text-orange">NAME:</span>
                  {{ rm.RmName }}</span
                >
                <span v-if="rm.RmEmail" class="w3-block ellipsis w3-text-white"
                  ><span class="size12 w3-text-orange">EMAIL:</span>
                  {{ rm.RmEmail }}</span
                >
                <span v-if="rm.RmPhoneNo" class="w3-block w3-text-white"
                  ><span class="size12 w3-text-orange">TEL:</span>
                  {{ rm.RmPhoneNo }}</span
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <LockScreen
      v-if="locked"
      picture=""
      :email="E_Mail"
      :username="ContactNo"
      :fullname="fullname"
    />
  </header>
</template>
<script>
import LockScreen from "./LockScreen.vue";
import { ShowRM } from "@/helpers/utilities";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  name: "Header",
  components: {
    LockScreen,
  },
  setup() {
    const store = useStore();
    const locked = computed(() => store.getters.isLockSreeen);
    const loggedIn = computed(() => store.state.isLoggedIn);
    const appTitle = computed(() => store.state.appTitle);
    const appVersion = computed(() => store.state.appVersion);
    const userData = computed(() => store.state.userData);
    const fullname = computed(() => userData.value.Account_Name);
    const ContactNo = computed(() => userData.value.contactNo);
    const E_Mail = computed(() => userData.value.EMail);

    const logout = () => {
      store.dispatch("logOutUser");
    };
    const lockScreen = () => {
      store.commit("LockSreeen");
    };

    if (!store.state.RM) store.dispatch("RM");
    const RM = computed(() => store.state.RM);
    return {
      locked,
      loggedIn,
      appTitle,
      appVersion,
      fullname,
      ContactNo,
      userData,
      E_Mail,
      logout,
      lockScreen,
      ShowRM,
      RM,
    };
  },
};
</script>
<style>
.investmentSummaryBox {
  max-width: 100%;
}
.investmentSummary {
  background-color: #8d1d1c;
  background-image: url("/base/img/bg-1.png");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}
.investmentSummary p {
  line-height: normal !important;
}
</style>

