<template>
  <section
    class="body-sign body-locked body-locked-inline"
    style="background-color: rgba(255, 0, 0, 0.9)"
  >
    <div class="center-sign">
      <div class="panel card-sign">
        <div class="card-body">
          <form>
            <div :class="[picture ? 'current-user' : '']" class="text-center">
              <img
                v-if="picture"
                :src="picture"
                :alt="fullname"
                class="rounded-circle"
              />
              <img
                v-else
                src="base/img/LClogo.jpg"
                style="width: 50%"
                :alt="fullname"
                class="rounded-circle"
              />
              <h4>{{ fullname }}</h4>
              <h5 class="mb-2">
                {{ username }}
              </h5>
              <!-- <p id="LockUserEmail" class="user-email m-0">{{ email }}</p> -->
            </div>

            <div class="form-group mb-lg">
              <div class="input-group">
                <input
                  v-model="Password"
                  name="Password"
                  type="password"
                  class="form-control form-control-lg"
                  placeholder="Password"
                /><span class="input-group-append"
                  ><span class="input-group-text"
                    ><i class="fas fa-lock"></i></span
                ></span>
              </div>
            </div>
            <p class="mt-xs mb-4 size13">
              Not {{ fullname }}?
              <a @click="Logout" href="#" class="">Logout</a>
            </p>
            <div class="row">
              <div class="col-12">
                <button
                  @click.prevent="UnlockScreen"
                  type="submit"
                  class="btn btn-primary pull-right"
                >
                  Unlock
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { useStore } from "vuex";
import { ref, toRef } from "vue";
import axios from "axios";
export default {
  props: ["picture", "email", "username", "fullname"],
  setup(props, { emit }) {
    const store = useStore();
    const ContactNo = toRef(props, "username");
    const Password = ref("");
    const UnlockScreen = () => {
      const username = ContactNo.value ?? "";
      const password = Password.value ?? "";
      if (!password) {
        store.dispatch("showAlert", {
          message: "Please enter your password",
          status: "error",
          showing: true,
        });
        return;
      }
      store.dispatch("showIndicator");
      axios
        .post("/api/login", {
          UserId: username,
          Password: password,
        })
        .then((response) => {
          store.dispatch("hideIndicator");
          if (response.data.status === "success") {
            if (response.data.result.Success) {
              store.commit("UnLockSreeen");
              emit("unlockScreen");
            } else {
              store.dispatch("showAlert", {
                message: "Invalid login",
                status: "error",
                showing: true,
              });
            }
          } else {
            store.dispatch("showAlert", {
              title: "",
              message: response.data.result,
              status: "error",
              showing: true,
            });
          }
        })
        .catch((err) => {
          store.dispatch("hideIndicator");
          store.dispatch("showAlert", {
            title: "Network error",
            message: err.message,
            status: "error",
            showing: true,
          });
        });
    };
    const Logout = () => {
      store.dispatch("logOutUser");
    };
    return {
      ContactNo,
      Password,
      UnlockScreen,
      Logout,
    };
  },
};
</script>
