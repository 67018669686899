<template>
  <label v-if="label" class="control-label">{{ label }} </label>
  <input
    v-bind="$attrs"
    class="form-control form-control-lg"
    :class="classes"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
  />
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    classes: {
      type: String,
      default: "",
    },
  },
};
</script>