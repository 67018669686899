export default [
  {
    title: 'Dashboard',
    name: 'dashboard',
    path: '/',
    icon: 'bx bxs-grid',
    children: []
  },
  {
    title: 'Investments',
    name: 'investments',
    path: '/investments',
    icon: 'bx bxs-pie-chart-alt-2',
    children: []
  },
  {
    title: 'MI',
    name: 'mi',
    path: '/mi',
    icon: 'bx bx-line-chart-down',
    children: []
  },
  {
    title: 'Savings',
    name: 'savings',
    path: '/savings',
    icon: 'bx bxs-wallet-alt',
    children: []
  },
  {
    title: 'Sub Accounts',
    name: 'sub-accounts',
    path: '/sub-accounts',
    icon: 'bx bx-subdirectory-left',
    children: []
  },
  {
    title: 'Uninvested Funds',
    name: 'uninvested',
    path: '/uninvested',
    icon: 'bx bx-minus-back',
    children: []
  },
  {
    title: 'Pending Liquidation',
    name: 'liquidation',
    path: '/liquidation',
    icon: 'bx bx-log-out-circle',
    children: []
  },
  {
    title: 'HMO',
    name: 'hmo',
    path: 'hmo',
    icon: 'bx bx-heart',
    children: []
  },
  {
    title: 'Gifts',
    name: 'gifts',
    path: '/gifts',
    icon: 'bx bx-gift',
    children: []
  },
  {
    title: 'Help & Feedback',
    name: 'complaints',
    path: '/complaints',
    icon: 'bx bx-help-circle',
    children: []
  },
  {
    title: 'Profile',
    name: 'profile',
    path: '/profile',
    icon: 'bx bx-user-circle',
    children: []
  }
]
