<template>
  <img
    class="m-3 fetching"
    v-if="store.state.fetching.PieChart_Data"
    src="base/img/loading.gif"
    width="80"
  />
  <template v-else>
    <section v-if="pieData.length" class="card shadow column w3-white mb-4">
      <div class="card-body m-0">
        <div class="mt-0 mb-2 bold">
          <p class="m-0 size11">Total Balance</p>
          <p class="m-0 size13 bold">
            {{ formatCurrency(totalBalance, "&#8358;") }}
          </p>
        </div>
        <pie-chart
          :seriesData="pieData"
          :colors="pieColors"
          class="mb-2"
          style="width: 100%"
          @equalColumns="equalColumns"
        ></pie-chart>
        <div v-for="(balance, key) in pieTotals" :key="key" class="row">
          <div class="col-5 mb-1 size11 ellipsis">
            {{ key.toUpperCase() }}
          </div>
          <div class="col-7 mb-1 bold size14">
            {{ formatCurrency(balance, "&#8358;") }}
          </div>
        </div>
      </div>
    </section>
    <div v-else class="p-3 w3-text-grey size14">Pie chart data not found</div>
  </template>
</template>
<script>
import { useStore } from "vuex";
import { computed, ref } from "vue";
import { formatCurrency } from "@/helpers/utilities";
import PieChart from "@/components/PieChart.vue";
export default {
  components: {
    PieChart,
  },
  props: [],
  setup(_, { emit }) {
    const store = useStore();
    const pieTotals = computed(() => store.state.PieChart_Data);

    if (!pieTotals.value) {
      store.dispatch("PieChart_Data");
    }
    const pieData = computed(() => {
      let pie_Data = [];
      for (let key in pieTotals.value) {
        pie_Data.push([key, pieTotals.value[key]]);
      }
      return pie_Data;
    });
    const equalColumns = () => {
      emit("equalColumns");
    };
    const pieColors = ref(["red", "blue", "orange"]);

    const totalBalance = computed(() => {
      let Total = 0;
      for (let key in pieTotals.value) {
        Total += pieTotals.value[key];
      }
      return Total;
    });
    const fundsTotal = computed(() => {
      const Funds = {};
      let i = 0;
      for (let key in pieTotals.value) {
        Funds[key] = {};
        Funds[key]["value"] = pieTotals.value[key];
        Funds[key]["formatted"] = pieTotals.value[key];
        Funds[key]["color"] = pieColors.value[i];
        i++;
      }
      return Funds;
    });

    return {
      store,
      formatCurrency,
      pieData,
      pieColors,
      pieTotals,
      totalBalance,
      fundsTotal,
      equalColumns,
    };
  },
};
</script>