import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const url = new URL(window.location.href)

import axios from 'axios'
const baseURL =
  process.env.NODE_ENV === 'production'
    ? 'cp/public'
    : 'http://' + url.hostname + '/lcclientcp/public'

axios.defaults.baseURL = baseURL

const app = createApp(App)

const requireComponent = require.context(
  './components',
  true,
  /Base[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName)

  const componentName = upperFirst(
    camelCase(
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  )
  // Register component globally
  app.component(componentName, componentConfig.default || componentConfig)
})
store.dispatch('getSettings')
store.dispatch('checkLocalStorage')
setInterval(function() {
  store.dispatch('checkIdleTime')
}, 1000)

app.use(store).use(router)
app.mount('#app')
