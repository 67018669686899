<template>
  <teleport to="#modalTeleports">
    <div
      :class="[loadIframe ? 'w3-block' : '']"
      class="w3-modal"
      style="padding: 2vh; padding-top: 35px"
    >
      <div
        class="w3-modal-content w3-animate-top w3-center bg-transparent"
        style="width: 95vw"
      >
        <button
          class="w3-button w3-red w3-round w3-round-xxlarge absolute"
          style="top: -30px; right: 10px"
          @click.prevent="closeIframe('TopupPaymentbox')"
        >
          x
        </button>

        <iframe
          name="TopupPaymentbox"
          id="TopupPaymentbox"
          src=""
          style="width: 100%; height: 95vh; border: 0px solid #fff"
        ></iframe>
      </div>
    </div>
  </teleport>
  <div :class="[TopUpBox ? 'w3-block' : '']" class="w3-modal">
    <div class="w3-modal-content w3-animate-top p-5">
      <form @submit.prevent="Payment" id="TopupPaymentForm" method="POST">
        <input type="hidden" :value="uuid()" name="uuid" />
        <input
          type="hidden"
          v-model="TopUp.InvestmentType"
          name="InvestmentType"
        />
        <input type="hidden" name="TransRef" v-model="TopUp.TransRef" />
        <input type="hidden" name="ContactNo" v-model="ContactNo" />
        <input type="hidden" name="PhoneNo" v-model="PhoneNo" />
        <input type="hidden" name="Email" v-model="EMail" />
        <input type="hidden" name="Fullname" v-model="Fullname" />
        <input type="hidden" name="callback" :value="referral" />
        <div class="row">
          <div class="col-md-6">
            <h3 class="w3-text-red line-height-100">
              You are about to topup your investment
            </h3>
          </div>
          <div class="col-md-6">
            <p class="my-0 w3-text-black mb-2">Topup Amount</p>
            <div class="input-group mb-5 max-width250">
              <span v-html="SETTINGS._CURRENCY" class="input-group-text"></span>
              <input
                name="AmountToPaid"
                v-model="TopUp.AmountToPaid"
                @blur="formatOnBlur($event, TopUp)"
                @focus="formatOnFocus($event, TopUp)"
                class="form-control form-control-lg"
              />
            </div>
            <div class="">
              <button
                type="submit"
                class="w3-button w3-red w3-hover-orange px-4"
              >
                Pay Now
              </button>
              <button
                type="button"
                @click.prevent="TopUpBox = false"
                class="w3-button w3-light-grey"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <button
    type="button"
    @click.prevent="TopUpBox = true"
    class="w3-button w3-mobile w3-text-white w3-red w3-hover-orange size14 px-4 py-3 mr-2 my-2"
  >
    <i class="bx bx-plus"></i> TOPUP INVESTMENT
  </button>
</template>
<script>
import {
  backendURL,
  formattedCurrency,
  formatOnFocus,
  formatOnBlur,
} from "@/helpers/utilities";
import { useStore } from "vuex";
import { ref, toRef, computed, onBeforeMount } from "vue";
import { v4 as uuid } from "uuid";
import usePayments from "@/composables/use-payments";

export default {
  props: [
    "Investmentyype",
    "contactno",
    "email",
    "fullname",
    "phoneno",
    "referral",
  ],
  setup(props) {
    const store = useStore();
    const ContactNo = toRef(props, "contactno");
    const EMail = toRef(props, "email");
    const PhoneNo = toRef(props, "phoneno");
    const Fullname = toRef(props, "fullname");
    const InvestmentType = toRef(props, "Investmentyype");
    const Referral = toRef(props, "referral");

    const SETTINGS = computed(() => store.state.appSettings);
    const TopUpBox = ref(false);

    const TopUp = ref({
      TransRef: "",
      AmountToPaid: "0.00",
      InvestmentType: InvestmentType.value,
      TempContactNo: ContactNo.value,
      ContactNo: ContactNo.value,
      uuid: uuid(),
      Email: EMail.value,
      Fullname: Fullname.value,
      PhoneNo: PhoneNo.value,
      callback: Referral.value,
    });

    const AmountToPaid = computed(() =>
      TopUp.value.AmountToPaid
        ? TopUp.value.AmountToPaid.replace(/,/g, "")
        : 0.0
    );

    const retry = ref(1);
    const PaymentSuccess = (data) => {
      console.log(data);
      if (data.Trx.apiCalled === 1) {
        TopUp.value.TransRef = "";
        store.dispatch("Dashboard");
        TopUpBox.value = false;
        TopUp.value.AmountToPaid = 0;
      } else {
        TopUp.value.TransRef = data.Trx.PaymentRef;
        if (retry.value <= 3) {
          retry.value++;
          IframeCallback(TopUp.value.TransRef, retry.value);
        }
      }
    };
    const { loadIframe, closeIframe, IframeCallback } = usePayments(
      PaymentSuccess
    );

    const Payment = () => {
      if (!(AmountToPaid.value > 0)) {
        store.dispatch("showAlert", {
          message: "Please enter an amount to Top Up",
          status: "error",
          showing: true,
        });
        return false;
      }
      if (TopUp.value.TransRef) {
        IframeCallback(TopUp.value.TransRef);
        return;
      }
      const form = document.getElementById("TopupPaymentForm");
      let gateway = "globalpay";
      if (TopUp.value.InvestmentType !== SETTINGS.value._MURABABA_PLAN_CODE) {
        form.setAttribute("target", "TopupPaymentbox");
        gateway = "paystack";
      }
      form.setAttribute(
        "action",
        backendURL + "/payment/" + gateway + "/topup"
      );
      form.submit();
      if (gateway === "paystack") loadIframe.value = true;
    };
    onBeforeMount(() => {
      store.dispatch("resetIdleTime");
    });
    return {
      formattedCurrency,
      formatOnFocus,
      formatOnBlur,
      SETTINGS,
      Payment,
      TopUpBox,
      TopUp,
      EMail,
      ContactNo,
      Fullname,
      PhoneNo,
      uuid,
      loadIframe,
      closeIframe,
    };
  },
};
</script>
