import { nextTick } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import store from '../store'
const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      title: 'Dashboard'
    }
  },
  {
    name: 'investments',
    path: '/investments',
    component: () => import('../views/Investments.vue'),
    meta: {
      requiresAuth: true,
      title: 'Investments'
    }
  },
  {
    name: 'mi',
    path: '/mi',
    component: () => import('../views/mi.vue'),
    meta: {
      requiresAuth: true,
      title: 'Investments'
    }
  },

  {
    name: 'savings',
    path: '/savings',
    component: () => import('../views/savings.vue'),
    meta: {
      requiresAuth: true,
      title: 'Savings'
    }
  },
  {
    name: 'sub-accounts',
    path: '/sub-accounts',
    component: () => import('../views/SubAccounts.vue'),
    meta: {
      requiresAuth: true,
      title: 'Sub Accounts'
    }
  },
  {
    name: 'uninvested',
    path: '/uninvested',
    component: () => import('../views/UninvestedFunds.vue'),
    meta: {
      requiresAuth: true,
      title: 'Uninvested Funds'
    }
  },
  {
    name: 'liquidation',
    path: '/liquidation',
    component: () => import('../views/Liquidation.vue'),
    meta: {
      requiresAuth: true,
      title: 'Pending Liquidation'
    }
  },
  {
    name: 'hmo',
    path: '/hmo',
    component: () => import('../views/Hmo.vue'),
    meta: {
      requiresAuth: true,
      title: 'HMo Enrollees'
    }
  },

  {
    name: 'gifts',
    path: '/gifts',
    component: () => import('../views/Gifts.vue'),
    meta: {
      requiresAuth: true,
      title: 'Gifts'
    }
  },

  {
    name: 'complaints',
    path: '/complaints',
    component: () => import('../views/Complaints.vue'),
    meta: {
      requiresAuth: true,
      title: 'Complaints'
    }
  },
  {
    name: 'profile',
    path: '/profile',
    component: () => import('../views/Profile.vue'),
    meta: {
      requiresAuth: true,
      title: 'Profile'
    }
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('../views/Login.vue'),
    meta: {
      requiresAuth: false,
      title: 'Login'
    }
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../views/404.vue'),
    meta: {
      requiresAuth: false,
      title: '404 | NOT FOUND'
    }
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import('../views/404.vue'),
    meta: {
      requiresAuth: false
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'nav-active w3-text-white bold w3-red'
})
const DEFAULT_TITLE = 'Lotus Capital Limited: Client Portal'
router.afterEach((to) => {
  nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE
  })
})
router.beforeEach((to, from, next) => {
  let authenticated = store.getters.isAuthenticated
  const lccUser_data = localStorage.getItem('lcc-logged-in-user')
  const lccUser = lccUser_data ? JSON.parse(lccUser_data) : null
  if (to.name !== 'login') {
    store.dispatch('checkLocalStorage')
    if (!authenticated && lccUser !== null) {
      authenticated = store.getters.isAuthenticated
    }
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (authenticated && lccUser) {
      next()
      return
    }
    next('/login')
  } else {
    if (authenticated && lccUser && to.name === 'login') {
      next('/')
      return
    }
    next()
  }
})
export default router
