<template>
  <div
    v-if="loadDetail"
    class="investment_Detail relative w3-white mb-5 box-shadow w3-animate-top max-width840"
  >
    <div v-if="activeInvestment" class="absolute top-0 right-0 z-index-3">
      <a
        href="javascript:;"
        @click="closeInvestmentDetail"
        style="top: -10px"
        class="w3-button w3-red w3-hover-deep-orange hover-effect-tiny no-hover-underline w3-right"
      >
        x</a
      >
      <div style="clear: both"></div>
    </div>
    <InvestmentDetailPage
      referral="investments"
      :investment="activeInvestment"
    ></InvestmentDetailPage>
  </div>
  <img
    class="m-3 fetching"
    v-if="fetching"
    src="base/img/loading.gif"
    width="80"
  />
  <div v-else class="row equalHeightColumns">
    <template v-if="DashBoard_Data.length">
        <div v-for="card in DashBoard_Data" :key="card.FundType"
          class="col-xl-4 col-md-6"
          @click="showInvestmentDetail(card)"
        >
          <section
            class="card w3-white mb-4 column w3-border cursor hover-effect w3-hover-light-grey w3-hover-border-red"
          >
            <div class="card-body bg-transparent m-0 pb-2">
              <div class="mt-0 mb-3 size13 bold pl-2 ellipsis">
                {{ card.FundName }} ({{ card.FundCode }})
              </div>
              <div class="w3-cell-row">
                <div class="w3-cell w3-cell-top">
                  <i
                    :class="[
                      card.GrowthIndex > 0 ? 'w3-text-green' : 'w3-text-red',
                    ]"
                    class="bx bx-line-chart size70"
                    style="font-weight: 100"
                  ></i>
                  <p
                    class="mt-0 mb-1 size11"
                    :class="[
                      card.GrowthIndex > 0 ? 'w3-text-green' : 'w3-text-red',
                    ]"
                  >
                    Growth
                    <span class="size12 my-0 bold mr-1">
                      {{ formatCurrency(card.GrowthIndex) }}
                    </span>
                    <i
                      class="bx size14"
                      :class="[
                        card.GrowthIndex > 0
                          ? 'bx-chevron-up'
                          : 'bx-chevron-down',
                      ]"
                    ></i>
                  </p>
                </div>
                <div class="w3-cell w3-cell-top">
                  <div class="w3-right-align">
                    <p class="mt-0 mb-1 size10 bold">TOTAL VALUE</p>
                    <p class="size15 bold mt-0 mb-2 w3-text-black">
                      {{ formatCurrency(card.ValuOfInvestment, "&#8358;") }}
                    </p>
                    <p class="mt-0 mb-1 size10 bold">TOTAL INVESTED</p>
                    <p class="size14 bold mt-0 mb-2 w3-text-black">
                      {{ formatCurrency(card.TotalAmountInvested, "&#8358;") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
    </template>
    <div v-else class="row equalHeightColumns">
      <div class="col w3-text-grey p-4 size14">
        Investments balances not found
      </div>
    </div>
  </div>
</template>
<script>
import { formatCurrency } from "@/helpers/utilities";
import { useStore } from "vuex";
import { ref, computed } from "vue";
import InvestmentDetailPage from "@/components/InvestmentDetailPage";
import { useRouter } from "vue-router";
export default {
  components: {
    InvestmentDetailPage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const DashBoard_Data = computed(() => store.state.DashBoard_Data);
    const fetching = computed(() => store.state.fetching.DashBoard_Data);

    if (!DashBoard_Data.value.length) {
      store.dispatch("Dashboard");
    }

    const activeInvestment = ref({});
    const loadDetail = ref(false);
    const showInvestmentDetail = (Investment) => {
      if (Investment.FundCode !== "MT-DEP") {
        router.push({ name: "investments" });
      } else {
        router.push({ name: "mi" });
      }
    };
    const closeInvestmentDetail = () => {
      loadDetail.value = false;
      activeInvestment.value = {};
    };
    return {
      fetching,
      formatCurrency,
      DashBoard_Data,
      activeInvestment,
      loadDetail,
      showInvestmentDetail,
      closeInvestmentDetail,
    };
  },
};
</script>
<style scoped>
p {
  line-height: normal !important;
}
</style>
