<template>
  <div class="p-0">
    <div v-if="Investment" class="p-0">
      <div class="px-4 py-4 w3-theme-d5">
        <div class="w3-text-orange m-0">Fund Name</div>
        <p class="bold size24 mt-0">
          {{ Investment.FundName }} ({{ Investment.FundCode }})
        </p>

        <div class="w3-row">
          <div class="w3-col m4 mb-3">
            <div class="w3-text-orange m-0">Fund Value</div>
            <p class="bold size16 mt-0 mb-3">
              {{ formattedCurrency(Investment.ValuOfInvestment, "&#8358;") }}
            </p>

            <div class="w3-text-orange m-0">Total Invested</div>
            <p class="size16 my-0">
              {{ formattedCurrency(Investment.TotalAmountInvested, "&#8358;") }}
            </p>
          </div>
          <div class="w3-col m4 mb-3">
            <div class="w3-text-orange m-0">Fund Unit</div>
            <p class="size16 mt-0 mb-3">
              {{ formattedCurrency(Investment.FundUnit) }}
            </p>
            <div class="w3-text-orange m-0">Current Price</div>
            <p class="size16 my-0">
              {{ formattedCurrency(Investment.CurrentFundPrice, "&#8358;") }}
            </p>
          </div>
          <div class="w3-col m4">
            <div class="w3-text-orange m-0">Growth</div>
            <p class="size16 my-0">
              {{ formattedCurrency(Investment.GrowthIndex) }}
              <i
                class="bx bx-tada size18"
                :class="[
                  Investment.GrowthIndex < 0
                    ? 'bx-down-arrow-alt w3-text-red'
                    : 'bx-up-arrow-alt w3-text-green',
                ]"
              ></i>
            </p>
          </div>
        </div>
        <InvestmentsTopup
          :Investmentyype="Investment.FundCode"
          :referral="referral"
          :contactno="contactno"
          :phoneno="phoneno"
          :email="email"
          :fullname="fullname"
        />
        <InvestmentsRedemption
          :referral="referral"
          :contactno="contactno"
          :email="email"
          :fullname="fullname"
          :phoneno="phoneno"
          :Investmentyype="Investment.FundCode"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { formattedCurrency } from "@/helpers/utilities";
import InvestmentsTopup from "@/components/InvestmentsTopup";
import InvestmentsRedemption from "@/components/InvestmentsRedemption";
import { onBeforeMount, toRef } from "vue";
import { useStore } from "vuex";

export default {
  props: [
    "investment",
    "referral",
    "contactno",
    "email",
    "fullname",
    "phoneno",
  ],
  components: {
    InvestmentsTopup,
    InvestmentsRedemption,
  },
  setup(props) {
    const Investment = toRef(props, "investment");

    onBeforeMount(() => {
      useStore().dispatch("resetIdleTime");
    });
    return {
      formattedCurrency,
      Investment,
    };
  },
};
</script>
<style scoped>
.box {
  padding: 20px 40px;
}
.no-wrap {
  white-space: nowrap;
}
.w3-theme-d5 p {
  color: #fff;
}
</style>