<template>
  <teleport to="#modalTeleports">
    <template v-if="allow_multiple_alerts">
      <div
        v-for="alert in alerts"
        :key="alert.uuid"
        :class="[alert.showing ? 'w3-block' : '']"
        class="w3-modal"
        style="z-index: 99999!important"
      >
        <div
          class="w3-modal-content w3-animate-top drop-shadow w3-border w3-center pt-4"
          style="width: 450px; border-width: 3px;"
          :class="[
            alert.status === 'error' ? 'w3-border-red' : (alert.status === 'success' ? 'w3-border-green' : 'w3-border-blue'),  alert.class ?? '', 
          ]"
        >
          <i
            v-if="alert.status === 'error'"
            class="mt-4 mb-3 mx-3 w3-text-red size48 fa fa-times-circle"
          ></i>
          <img v-else class="m-3" src="base/img/check-circle.gif" width="48" />
          <div class="px-4 pt-2">
            <h5
              v-if="alert.title !== ''"
              :class="
                alert.status === 'error' ? 'w3-text-red' : 'w3-text-green'
              "
              class="mt-0 mb-3 mx-0"
            >
              {{ alert.title }}
            </h5>
            <div
              v-if="alert.message !== ''"
              class="pt-2 pb-4"
              style="max-height: 500px; overflow: auto"
            >
              {{ alert.message }}
            </div>
          </div>
          <div class="footer w3-border-top w3-right-align">
            <button
              @click="store.dispatch('hideAlert', alert.uuid)"
              class="w3-button w3-white w3-hover-white w3-hoverable hover-bold"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div :class="[alerts.showing ? 'w3-block' : '']" class="w3-modal">
        <div
          class="w3-modal-content w3-animate-top drop-shadow w3-border w3-center pt-4"
          style="width: 450px; border-width: 3px;"
          :class="[
            alerts.status === 'error' ? 'w3-border-red' : (alerts.status === 'success' ? 'w3-border-green' : 'w3-border-blue'),  alerts.class ?? '', 
          ]"
        >
          <i
            v-if="alerts.status === 'error'"
            class="mt-4 mb-3 mx-3 w3-text-red size48 fa fa-times-circle"
          ></i>
          <img v-else-if="alerts.status === 'success'" class="m-3" src="base/img/check-circle.gif" width="48" />
          <div class="px-4 pt-2">
            <h5
              v-if="alerts.title !== ''"
              :class="
                alerts.status === 'error' ? 'w3-text-red' : (alerts.status === 'error' ? 'w3-text-green' : 'w3-text-blue')
              "
              class="mt-0 mb-3 mx-0"
            >
              {{ alerts.title }}
            </h5>
            <div
              v-if="alerts.message !== ''"
              class="pt-2 pb-4"
              style="max-height: 500px; overflow: auto"
              v-html="alerts.message"
            >
              
            </div>
          </div>
          <div class="footer w3-border-top w3-right-align">
            <button
              @click="store.dispatch('hideAlert', alerts.uuid)"
              class="w3-button w3-white w3-hover-white w3-hoverable hover-bold"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </template>
  </teleport>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    const alerts = computed(() => store.state.ModalAlert);
    const allow_multiple_alerts = computed(
      () => store.state.appSettings._ALLOW_MULTIPLE_ALERTS
    );
    return {
      store,
      alerts,
      allow_multiple_alerts,
    };
  },
};
</script>

<style>
</style>