import $ from 'jquery'

export const jquery = {
  mounted: function() {
    function equalHeightColumns() {
      let isMobile = window.matchMedia('only screen and (max-width: 760px)')
        .matches
      if (!isMobile) {
        $('.equalHeightColumns').each(function() {
          var highestBox = 0
          $('.column', this).each(function() {
            if ($(this).height() > highestBox) {
              highestBox = $(this).height()
            }
          })
          $('.column', this).height(highestBox)
        })
      }
    }
    equalHeightColumns()
    $(window).resize(function() {
      equalHeightColumns()
    })
  },
  methods: {
    scrollToElem: (elem) => {
      document.querySelector(elem).scrollIntoView({
        behavior: 'smooth'
      })
    }
  }
}
