import { computed } from 'vue'
import { useStore } from 'vuex'

export function useState(arr) {
  const { state } = useStore()
  const keypair = arr.map((s) => [s, computed((s) => state[s])])
  return Object.fromEntries(keypair)
}
export function useGetters(arr) {
  const { getters } = useStore()
  const keypair = arr.map((g) => [g, computed(() => getters[g])])
  return Object.fromEntries(keypair)
}
export function useMutations(arr) {
  const { commit } = useStore()
  const keypair = arr.map((m) => [m, (input) => commit(m, input)])
  return Object.fromEntries(keypair)
}
