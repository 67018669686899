<template>
  <img
    class="m-3 fetching"
    v-if="fetching"
    src="base/img/loading.gif"
    width="80"
  />
  <template v-else>
    <section
      v-if="PriceChart.SeriesData.length > 0"
      id="priceChartBox"
      class="card shadow column w3-white mb-4"
      style="height: 400px"
    >
      <div class="card-body row m-0">
        <div class="col-12">
          <div class="mt-0 mb-2 size13 bold">
            <p class="m-0 w3-left">Price Charts</p>

            <select
              v-model="PriceChart.activeSeries"
              class="form-control width100 w3-right"
            >
              <option
                v-for="pType in PriceChart.SeriesTypes"
                :key="pType"
                :value="pType"
              >
                {{ pType }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-12">
          <div v-for="series in PriceChart.SeriesData" :key="series.name">
            <div v-if="series.name === PriceChart.activeSeries">
              <lines-chart
                :chatTitle="series.name"
                :seriesData="series.data"
                :categories="series.categories"
                @equalColumns="equalColumns"
              ></lines-chart>
            </div>
          </div>
          <div style="clear: both"></div>
        </div>
      </div>
    </section>
    <div v-else class="p-3 w3-text-grey size14">Price chart data no found</div>
  </template>
</template>
<script>
import { useStore } from "vuex";
import { computed } from "vue";
import LinesChart from "@/components/LinesChart.vue";
export default {
  components: {
    LinesChart,
  },
  props: [],
  setup(_, { emit }) {
    const store = useStore();
    const PriceChart = computed(() => store.state.PriceChart_Data);
    const fetching = computed(() => store.state.fetching.PriceChart_Data);

     if (!PriceChart.value) {
      store.dispatch("PriceChart_Data");
    }

    const equalColumns = () => {
      emit("equalColumns");
    };
    return {
      fetching,
      PriceChart,
      equalColumns,
    };
  },
};
</script>