import $ from 'jquery'
import store from '../store'
const url = new URL(window.location.href);

export const backendURL =
  process.env.NODE_ENV === 'production'
    ? 'cp/public'
    : 'http://' + url.hostname + '/lcclientcp/public'
export const formatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})
export function formatNumbers(digits = 2) {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits
  })
}
export function formatCurrency(amount, symbol) {
  if (!symbol) {
    return formatter.format(amount)
  }
  return parseFloat(amount) < 0
    ? '(' + symbol + formatter.format(Math.abs(amount)) + ')'
    : symbol + formatter.format(amount)
}
export function formattedCurrency(amount, symbol) {
  if (!symbol) {
    return formatter.format(amount)
  }
  return parseFloat(amount) < 0
    ? '(' + symbol + formatter.format(Math.abs(amount)) + ')'
    : symbol + formatter.format(amount)
}

export function formatOnFocus(e, RefValue) {
  const inputName = e.target.name
  RefValue[inputName] = e.target.value.replace(/,/g, '');
}
export function formatOnBlur(e, RefValue) {
  const inputName = e.target.name
  RefValue[inputName] = formatter.format(e.target.value)
}
export function formattedDate(inputDate) {
  // input => yyyy-mm-dd
  // output => dd/mm/yyyy
  if (inputDate?.includes('/')) return inputDate
  const fomattedDate = inputDate?.substring(0, 10)?.split('-')
  if (!fomattedDate || fomattedDate[0] < 1980) return '-'
  return fomattedDate[2] + '/' + fomattedDate[1] + '/' + fomattedDate[0]
}
export function dbDateFormat(inputDate) {
  // input => dd/mm/yyyy
  // input => yyyy-mm-dd
  if (inputDate.includes('-')) return inputDate
  const fomattedDate = inputDate?.split('/')
  return fomattedDate[2] + '-' + fomattedDate[1] + '-' + fomattedDate[0]
}
export function hideEmail(email) {
  return email.replace(/(.{2})(.*)(?=@)/, function(gp1, gp2, gp3) {
    for (let i = 0; i < gp3.length; i++) {
      gp2 += '*'
    }
    return gp2
  })
}
export function queryParameter() {
  let uri = window.location.href.split('?')
  if (uri.length == 2) {
    let vars = uri[1].split('&')
    let parameters = {}
    let tmp = ''
    vars.forEach(function(v) {
      tmp = v.split('=')
      if (tmp.length == 2) parameters[tmp[0]] = tmp[1]
    })
    return parameters
  }
  return false
}
export function equalHeightColumns() {
  let isMobile = window.matchMedia('only screen and (max-width: 760px)').matches
  if (!isMobile) {
    $('.equalHeightColumns').each(function() {
      var highestBox = 0
      $('.column', this).each(function() {
        if ($(this).height() > highestBox) {
          highestBox = $(this).height()
        }
      })
      $('.column', this).height(highestBox)
    })
  }
}
export const Utility = {
  Error: (message) => {
    if (!message) return
    store.dispatch('showAlert', {
      message: message,
      status: 'error',
      showing: true
    })
  },
  Success: (message) => {
    if (!message) return
    store.dispatch('showAlert', {
      message: message,
      status: 'success',
      showing: true
    })
  },
  showIndicator: () => {
    store.dispatch('showIndicator')
  },
  hideIndicator: () => {
    store.dispatch('hideIndicator')
  }
}
export function ShowRM(rm) {
  const detail = 
    '<div class="">\
        <span class="w3-block bold w3-border-bottom w3-border-red pb-2 mb-3 w3-text-red">ACCOUNT MANAGER</span>\
        <span class="w3-block size12 bold w3-text-red">NAME</span>\
        <span class="w3-block size18 bold mb-3">' + rm.RmName + '</span>\
        <span class="w3-block size12 bold w3-text-red">EMAIL</span>\
        <span v-if="rm.RmEmail" class="w3-block size15 mb-3">' + rm.RmEmail + '</span>\
        <span class="w3-block size12 bold w3-text-red">TELEPHONE</span>\
        <span v-if="rm.RmPhoneNo" class="w3-block size15">' + rm.RmPhoneNo + '</span>\
    </div>';
  store.dispatch('showAlert', {
    message: detail,
    status: 'info',
    class: 'investmentSummary w3-text-white',
    showing: true
  })
}
