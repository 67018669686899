import { createStore } from 'vuex'
import router from '../router/index'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
// const Timer = 5000
export default createStore({
  state: {
    isLoggedIn: false,
    userData: {
      uuid: '',
      Account_Name: '',
      Account_No: '',
      contactNo: '',
      EMail: '',
      PhoneNo: '',
      lastLogin: ''
    },
    ModalAlert: {},
    loadingIndicator: false,
    loadingLogoutIndicator: false,
    appTitle: 'Lotus Capital Limited',
    appVersion: 'Version 1.0.1',
    sidebarLeftState: 'expanded', // 'collapsed' or 'expanded'
    IdleLockscreenTime: 2, // 5 minutes
    IdleLogoutTime: 15, // 5 minutes
    appSettings: {},
    fetching: {},
    DashBoard_Data: {},
    Balances_Data: {},
    MITStatement: {},
    MITransactions: {},
    SavingsPlans: {},
    PieChart_Data: {},
    AccountBalancesError: {},
    Products: {},
    Transactions: [],
    PriceChart_Data: {
      SeriesTypes: [],
      SeriesData: [],
      priceChart: {},
      activeSeries: ''
    },
    Complaints_GetCategory: [],
    Profile: {},
    InvestmentCodes: [],
    UninvestedFund: []
  },
  mutations: {
    updateConfig: (state, config) => {
      state.appSettings = config
      state.IdleLogoutTime = config?._IDLE_LOGOUT_TIME
      state.IdleLockscreenTime = config?._IDLE_LOCKSCREEN_TIME
    },
    logInUser: (state, user_data) => {
      state.userData = {
        uuid: uuidv4(),
        Account_Name: user_data.Name + ' ' + user_data.Name_2,
        Account_No: user_data.Account_No_,
        contactNo: user_data.No_,
        EMail: user_data.E_Mail,
        PhoneNo: user_data.Phone_No_,
        lastLogin: new Date()
      }
      state.lock = false
      localStorage.removeItem('lccL')
      state.isLoggedIn = true
      localStorage.setItem('lcc-logged-in-user', JSON.stringify(state.userData))
      router.push({ name: 'dashboard' })
    },
    LockSreeen: (state) => {
      if (state.isLoggedIn) {
        state.lock = true
        localStorage.setItem('lccL', 'LCCLIENT')
      }
    },
    UnLockSreeen: (state) => {
      if (localStorage.getItem('lcc-logged-in-user') !== null) {
        const lccUser_data = localStorage.getItem('lcc-logged-in-user')
        const lccUser = JSON.parse(lccUser_data)
        lccUser.lastLogin = new Date()
        state.userData = lccUser
        localStorage.setItem(
          'lcc-logged-in-user',
          JSON.stringify(state.userData)
        )
      }
      state.lock = false
      localStorage.removeItem('lccL')
    },
    loginUserFromStorage: (state, user_data) => {
      if (user_data.uuid) {
        state.userData = user_data
      } else {
        const uuid = uuidv4()
        user_data.uuid = uuid
        state.userData = user_data
      }
      state.userData.lastLogin = new Date()
      state.isLoggedIn = true
      localStorage.setItem('lcc-logged-in-user', JSON.stringify(state.userData))
    },
    logOutUser: (state) => {
      state.isLoggedIn = false
      state.lock = false
      state.userData = {}
      state.fetching = {}
      state.DashBoard_Data = {}
      state.Balances_Data = {}
      state.MITStatement = {}
      state.MITransactions = {}
      state.SavingsPlans = {}
      state.PieChart_Data = {}
      state.AccountBalancesError = {}
      state.Products = {}
      state.Transactions = []
      state.RedemptionALLHistory = []
      state.Profile = {}
      state.RM = {}
      state.InvestmentCodes = []
      state.UninvestedFund = []
      state.GetAllSubAccounts = []
      state.PriceChart_Data = {
        SeriesTypes: [],
        SeriesData: [],
        priceChart: {},
        activeSeries: ''
      }
      localStorage.removeItem('lccL')
      localStorage.removeItem('lcc-logged-in-user')
      router.push('/login')
    },
    showLogoutIndicator: (state) => {
      state.loadingLogoutIndicator = true
    },
    hideLogoutIndicator: (state) => {
      state.loadingLogoutIndicator = false
    },
    showIndicator: (state) => {
      state.loadingIndicator = true
    },
    hideIndicator: (state) => {
      state.loadingIndicator = false
    },
    showAlert: (state, payload) => {
      const uuid = uuidv4()
      if (state.appSettings._ALLOW_MULTIPLE_ALERTS) {
        state.ModalAlert[uuid] = {
          uuid: uuid,
          title: payload.title ?? '',
          message: payload.message ?? '',
          status: payload.status ?? 'success',
          class: payload.status ?? '',
          showing: true
        }
      } else {
        if (state.ModalAlert.showing) return
        state.ModalAlert = {
          uuid: uuid,
          title: payload.title ?? '',
          message: payload.message ?? '',
          status: payload.status ?? 'success',
          showing: true
        }
      }
    },
    hideAlert: (state, uuid) => {
      if (!state.appSettings._ALLOW_MULTIPLE_ALERTS) {
        state.ModalAlert.showing = false
      } else {
        state.ModalAlert[uuid].showing = false
      }
    },
    toggleSidebar: (state, sidebarState) => {
      localStorage.setItem('sidebar-left-state', sidebarState)
      state.sidebarLeftState = sidebarState
    }
  },
  actions: {
    StartUp({ dispatch }) {
      dispatch('Dashboard')
      dispatch('RM')
      //dispatch('MIRunningBal')
      //dispatch('SavingsPlans')
      //dispatch('getInvestmentBalance')
      // dispatch('TransactionALLHistory', {
      //   StartDate: '',
      //   EndDate: ''
      // })
      dispatch('PieChart_Data')
      dispatch('GetProducts')
      dispatch('PriceChart_Data')
    },
    Profile({ dispatch, state }) {
      if (!state.userData.contactNo) {
        return
      }
      state.fetching.Profile = true
      axios({
        method: 'get',
        url: '/api/Contacts/single',
        params: {
          ContactNo: state.userData.contactNo
        }
      })
        .then((response) => {
          state.fetching.Profile = false
          if (response.data.status === 'success') {
            state.Profile = response.data.result
          } else {
            if (response.data.result) {
              dispatch('showAlert', {
                message: response.data.result,
                status: 'error',
                showing: true
              })
            }
          }
        })
        .catch((error) => {
          dispatch('showAlert', {
            title: 'Error: ' + error.code,
            message: error.message,
            status: 'error',
            showing: true
          })
        })
        .finally(() => {
          state.fetching.Profile = false
        })
    },
    RM({ dispatch, state }) {
      if (!state.userData.contactNo) {
        return
      }
      state.fetching.RM = true
      axios({
        method: 'get',
        url: '/api/Rm/GetRmDetails?ContactNo=' + state.userData.contactNo,
        params: {
          ContactNo: state.userData.contactNo
        }
      })
        .then((response) => {
          state.fetching.RM = false
          if (response.data.status === 'success') {
            state.RM = response.data.result
          } else {
            if (response.data.result) {
              dispatch('showAlert', {
                message: response.data.result,
                status: 'error',
                showing: true
              })
            }
          }
        })
        .catch((error) => {
          dispatch('showAlert', {
            title: 'Error: ' + error.code,
            message: error.message,
            status: 'error',
            showing: true
          })
        })
        .finally(() => {
          state.fetching.RM = false
        })
    },
    GetAllSubAccounts({ dispatch, state }) {
      if (!state.userData.contactNo) {
        return
      }
      state.fetching.GetAllSubAccounts = true
      axios({
        method: 'get',
        url: '/api/SubAccounts/GetAllSubAccounts',
        params: {
          ParentID: state.userData.contactNo
        }
      })
        .then((response) => {
          state.fetching.GetAllSubAccounts = false
          if (response.data.status === 'success') {
            state.GetAllSubAccounts = response.data.result
          } else {
            if (response.data.result) {
              dispatch('showAlert', {
                message: response.data.result,
                status: 'error',
                showing: true
              })
            }
          }
        })
        .catch((error) => {
          dispatch('showAlert', {
            title: 'Error: ' + error.code,
            message: error.message,
            status: 'error',
            showing: true
          })
        })
        .finally(() => {
          state.fetching.Profile = false
        })
    },
    Complaints_GetCategory({ dispatch, state }) {
      state.fetching.Complaints_GetCategory = true
      axios({
        method: 'get',
        url: '/api/Complaints/GetCategory'
      })
        .then((response) => {
          state.fetching.Complaints_GetCategory = false
          if (response.data.status === 'success') {
            state.Complaints_GetCategory = response.data.result
          } else {
            if (response.data.result) {
              dispatch('showAlert', {
                message: response.data.result,
                status: 'error',
                showing: true
              })
            }
          }
        })
        .catch((error) => {
          dispatch('showAlert', {
            title: 'Error: ' + error.code,
            message: error.message,
            status: 'error',
            showing: true
          })
        })
        .finally(() => {
          state.fetching.PriceChart_Data = false
        })
    },
    PriceChart_Data({ dispatch, state }) {
      state.fetching.PriceChart_Data = true
      axios({
        method: 'get',
        url: '/api/dashboard_data/7/PriceChart'
      })
        .then((response) => {
          state.fetching.PriceChart_Data = false
          if (response.data.status === 'success') {
            const cData = response.data.result
            const SeriesTypes = []
            const SeriesData = []
            const priceChart = {}
            let activeSeries = ''
            cData.forEach((items, i) => {
              let FundCode = items.FundCode
              if (i === 0) activeSeries = FundCode
              SeriesTypes.push(FundCode)
              priceChart[FundCode] = {
                Bid: [],
                UnitPrice: [],
                OfferPrice: [],
                Categories: []
              }
              items.chartClass.reverse()
              items.chartClass.forEach((price) => {
                priceChart[FundCode].Bid.push([
                  price.Date.substring(0, 10),
                  price.Bid
                ])
                priceChart[FundCode].UnitPrice.push([
                  price.Date.substring(0, 10),
                  price.UnitPrice
                ])
                priceChart[FundCode].OfferPrice.push([
                  price.Date.substring(0, 10),
                  price.OfferPrice
                ])
                priceChart[FundCode].Categories.push(
                  price.Date.substring(0, 10)
                )
              })
              SeriesData.push({
                name: FundCode,
                categories: priceChart[FundCode].Categories,
                data: [
                  {
                    name: 'BID',
                    data: priceChart[FundCode].Bid
                  },
                  {
                    name: 'UNIT PRICE',
                    data: priceChart[FundCode].UnitPrice
                  },
                  {
                    name: 'OTHER PRICE',
                    data: priceChart[FundCode].OfferPrice
                  }
                ]
              })
            })
            state.PriceChart_Data = {
              priceChart,
              SeriesTypes,
              SeriesData,
              activeSeries
            }
          } else {
            if (response.data.result) {
              dispatch('showAlert', {
                message: response.data.result,
                status: 'error',
                showing: true
              })
            }
          }
        })
        .catch((error) => {
          dispatch('showAlert', {
            title: 'Error: ' + error.code,
            message: error.message,
            status: 'error',
            showing: true
          })
        })
        .finally(() => {
          state.fetching.PriceChart_Data = false
        })
    },
    Dashboard({ dispatch, state }) {
      if (!state.userData.contactNo) {
        return
      }
      state.fetching.DashBoard_Data = true
      axios({
        method: 'get',
        // timeout: Timer,
        url: '/api/dashboard_data/' + state.userData.contactNo + '/DashBoard'
      })
        .then((response) => {
          state.fetching.DashBoard_Data = false
          if (response.data.status === 'success') {
            const DashBoard_Data = response.data.result
            state.DashBoard_Data = DashBoard_Data

            const InvestmentCodes = []
            for (let inv in DashBoard_Data) {
              let Code = DashBoard_Data[inv].FundCode
              if (Code !== state.appSettings._MURABABA_PLAN_CODE) {
                InvestmentCodes.push(Code)
              }
            }
            state.InvestmentCodes = InvestmentCodes
          } else {
            if (response.data.result) {
              dispatch('showAlert', {
                message: response.data.result,
                status: 'error',
                showing: true
              })
            }
          }
        })
        .catch((error) => {
          dispatch('showAlert', {
            title: 'Error: ' + error.code,
            message: error.message,
            status: 'error',
            showing: true
          })
        })
        .finally(() => {
          state.fetching.DashBoard_Data = false
        })
    },
    MITStatement({ dispatch, state }) {
      if (!state.userData.contactNo) {
        return
      }
      state.fetching.MITStatement = true
      axios({
        method: 'get',
        // timeout: Timer,
        url: '/api/dashboard_data/' + state.userData.contactNo + '/MITStatement'
      })
        .then((response) => {
          state.fetching.MITStatement = false
          if (response.data.status === 'success') {
            state.MITStatement = response.data.result
          } else {
            if (response.data.result) {
              dispatch('showAlert', {
                message: response.data.result,
                status: 'error',
                showing: true
              })
            }
          }
        })
        .catch((error) => {
          dispatch('showAlert', {
            title: 'Error: ' + error.code,
            message: error.message,
            status: 'error',
            showing: true
          })
        })
        .finally(() => {
          state.fetching.MITStatement = false
        })
    },
    MIRunningBal({ dispatch, state }) {
      if (!state.userData.contactNo) {
        return
      }
      state.fetching.MIRunningBal = true
      axios({
        method: 'get',
        // timeout: Timer,
        url: '/api/dashboard_data/' + state.userData.contactNo + '/MIRunningBal'
      })
        .then((response) => {
          state.fetching.MIRunningBal = false
          if (response.data.status === 'success') {
            state.MIRunningBal = response.data.result
          } else {
            if (response.data.result) {
              dispatch('showAlert', {
                message: response.data.result,
                status: 'error',
                showing: true
              })
            }
          }
        })
        .catch((error) => {
          dispatch('showAlert', {
            title: 'Error: ' + error.code,
            message: error.message,
            status: 'error',
            showing: true
          })
        })
        .finally(() => {
          state.fetching.MIRunningBal = false
        })
    },
    UninvestedFund({ dispatch, state }, payload) {
      if (!state.userData.contactNo) {
        return
      }
      state.fetching.UninvestedFund = true
      axios({
        method: 'post',
        // timeout: Timer,
        url: '/api/UninvestedFund',
        data: {
          UserId: state.userData.contactNo,
          StartDate: payload && payload.StartDate ? payload.StartDate : '',
          EndDate: payload && payload.EndDate ? payload.EndDate : ''
        }
      })
        .then((response) => {
          state.fetching.UninvestedFund = false
          if (response.data.status === 'success') {
            state.UninvestedFund = response.data.result
          } else {
            if (response.data.result) {
              dispatch('showAlert', {
                message: response.data.result,
                status: 'error',
                showing: true
              })
            }
          }
        })
        .catch((error) => {
          dispatch('showAlert', {
            title: 'Error: ' + error.code,
            message: error.message,
            status: 'error',
            showing: true
          })
        })
        .finally(() => {
          state.fetching.UninvestedFund = false
        })
    },
    TransactionALLHistory({ dispatch, state }, payload) {
      if (!state.userData.contactNo) {
        return
      }
      state.fetching.Transactions = true
      axios({
        method: 'post',
        // timeout: Timer,
        url: '/api/TransactionALLHistory',
        data: {
          UserId: state.userData.contactNo,
          // FundCode: payload && payload.FundCode ? payload.FundCode : 'FIF',
          StartDate: payload && payload.StartDate ? payload.StartDate : '',
          EndDate: payload && payload.EndDate ? payload.EndDate : ''
        }
      })
        .then((response) => {
          state.fetching.Transactions = false
          if (response.data.status === 'success') {
            state.Transactions = response.data.result
          } else {
            if (response.data.result) {
              dispatch('showAlert', {
                message: response.data.result,
                status: 'error',
                showing: true
              })
            }
          }
        })
        .catch((error) => {
          dispatch('showAlert', {
            title: 'Error: ' + error.code,
            message: error.message,
            status: 'error',
            showing: true
          })
        })
        .finally(() => {
          state.fetching.Transactions = false
        })
    },
    RedemptionALLHistory({ dispatch, state }, payload) {
      if (!state.userData.contactNo) {
        return
      }
      state.fetching.Transactions = true
      axios({
        method: 'post',
        url: '/api/RedemptionALLHistory',
        data: {
          UserId: state.userData.contactNo,
          StartDate: payload && payload.StartDate ? payload.StartDate : '',
          EndDate: payload && payload.EndDate ? payload.EndDate : ''
        }
      })
        .then((response) => {
          state.fetching.RedemptionALLHistory = false
          if (response.data.status === 'success') {
            state.RedemptionALLHistory = response.data.result
          } else {
            if (response.data.result) {
              dispatch('showAlert', {
                message: response.data.result,
                status: 'error',
                showing: true
              })
            }
          }
        })
        .catch((error) => {
          dispatch('showAlert', {
            title: 'Error: ' + error.code,
            message: error.message,
            status: 'error',
            showing: true
          })
        })
        .finally(() => {
          state.fetching.RedemptionALLHistory = false
        })
    },
    MITransactionHistory({ dispatch, state }, payload) {
      if (!state.userData.contactNo) {
        return
      }
      state.fetching.MITransactions = true
      axios({
        method: 'post',
        // timeout: Timer,
        url: '/api/MITransactionHistory',
        data: {
          UserId: state.userData.contactNo,
          StartDate: payload && payload.StartDate ? payload.StartDate : '',
          EndDate: payload && payload.EndDate ? payload.EndDate : ''
        }
      })
        .then((response) => {
          state.fetching.MITransactions = false
          if (response.data.status === 'success') {
            state.MITransactions = response.data.result
          } else {
            if (response.data.result) {
              dispatch('showAlert', {
                message: response.data.result,
                status: 'error',
                showing: true
              })
            }
          }
        })
        .catch((error) => {
          dispatch('showAlert', {
            title: 'Error: ' + error.code,
            message: error.message,
            status: 'error',
            showing: true
          })
        })
        .finally(() => {
          state.fetching.MITransactions = false
        })
    },
    SavingsPlans({ dispatch, state }) {
      if (!state.userData.contactNo) {
        return
      }
      state.fetching.SavingsPlans = true
      axios({
        method: 'get',
        // timeout: Timer,
        url: '/api/savings/' + state.userData.contactNo
      })
        .then((response) => {
          state.fetching.SavingsPlans = false
          if (response.data.status === 'success') {
            state.SavingsPlans = response.data.result
          } else {
            if (response.data.result) {
              dispatch('showAlert', {
                message: response.data.result,
                status: 'error',
                showing: true
              })
            }
          }
        })
        .catch((error) => {
          dispatch('showAlert', {
            title: 'Error: ' + error.code,
            message: error.message,
            status: 'error',
            showing: true
          })
        })
        .finally(() => {
          state.fetching.SavingsPlans = false
        })
    },
    getInvestmentBalance({ dispatch, state }) {
      if (!state.userData.contactNo) {
        return
      }
      state.fetching.getInvestmentBalance = true
      axios({
        method: 'get',
        // timeout: Timer,
        url: '/api/dashboard_data/' + state.userData.contactNo + '/Balances'
      })
        .then((response) => {
          state.fetching.getInvestmentBalance = false
          if (response.data.status === 'success') {
            state.Balances_Data = response.data.result
          } else {
            if (response.data.result) {
              dispatch('showAlert', {
                message: response.data.result,
                status: 'error',
                showing: true
              })
            }
          }
        })
        .catch((error) => {
          dispatch('showAlert', {
            title: 'Error: ' + error.code,
            message: error.message,
            status: 'error',
            showing: true
          })
        })
        .finally(() => {
          state.fetching.getInvestmentBalance = false
        })
    },
    PieChart_Data({ dispatch, state }) {
      if (!state.userData.contactNo) {
        return
      }
      state.fetching.PieChart_Data = true
      axios({
        method: 'get',
        // timeout: Timer,
        url: '/api/dashboard_data/' + state.userData.contactNo + '/PieChart'
      })
        .then((response) => {
          state.fetching.PieChart_Data = false
          if (response.data.status === 'success') {
            state.PieChart_Data = response.data.result.balances
            state.AccountBalancesError = response.data.result.error
          } else {
            if (response.data.result) {
              dispatch('showAlert', {
                message: response.data.result,
                status: 'error',
                showing: true
              })
            }
          }
        })
        .catch((error) => {
          dispatch('showAlert', {
            title: 'Error: ' + error.code,
            message: error.message,
            status: 'error',
            showing: true
          })
        })
        .finally(() => {
          state.fetching.PieChart_Data = false
        })
    },
    GetProducts({ dispatch, state }) {
      state.fetching.GetProducts = true
      axios({
        method: 'get',
        // timeout: Timer,
        url: '/api/GetProducts'
      })
        .then((response) => {
          state.fetching.GetProducts = false
          if (response.data.status === 'success') {
            state.Products = response.data.result
          } else {
            if (response.data.result) {
              dispatch('showAlert', {
                message: response.data.result,
                status: 'error',
                showing: true
              })
            }
          }
        })
        .catch((error) => {
          dispatch('showAlert', {
            title: 'Error: ' + error.code,
            message: error.message,
            status: 'error',
            showing: true
          })
        })
        .finally(() => {
          state.fetching.GetProducts = false
        })
    },
    getSettings({ commit }) {
      commit('showIndicator')
      axios
        .get('/api/config')
        .then((response) => {
          commit('hideIndicator')
          if (response.data.status === 'success') {
            commit('updateConfig', response.data.result)
          } else {
            commit('showAlert', {
              message: response.data.result,
              status: 'error',
              showing: true
            })
          }
        })
        .catch((error) => {
          commit('showAlert', {
            title: 'Error: ' + error.code,
            message: error.message,
            status: 'error',
            showing: true
          })
        })
    },
    logInUser({ commit, dispatch }, loginInfo) {
      const username = loginInfo.username
      const password = loginInfo.password
      commit('showIndicator')
      axios
        .post('/api/login', {
          UserId: username,
          Password: password
        })
        .then((res) => {
          const status = res.data.status
          const response = res.data.result
          const Success = response.Success
          const Data = response.Data
          commit('hideIndicator')
          if (status === 'success') {
            if (Success) {
              commit('logInUser', Data)
              dispatch('StartUp')
            } else {
              let msg = {
                title: 'Authentication Error',
                message: 'Your login is invalid',
                status: 'error',
                showing: true
              }
              commit('showAlert', msg)
            }
          } else {
            let msg = {
              title: '',
              message: response,
              status: 'error',
              showing: true
            }
            commit('showAlert', msg)
          }
        })
        .catch((err) => {
          commit('hideIndicator')
          let msg = {
            title: 'Network error',
            message: err.message,
            status: 'error',
            showing: true
          }
          commit('showAlert', msg)
        })
    },
    checkLocalStorage({ commit, dispatch }) {
      if (localStorage.getItem('lcc-logged-in-user') !== null) {
        const lccUser_data = localStorage.getItem('lcc-logged-in-user')
        const lccUser = JSON.parse(lccUser_data)
        lccUser.lastLogin = new Date()
        commit('loginUserFromStorage', lccUser)
        dispatch('StartUp')
      }
    },
    checkIdleTime({ commit, state }) {
      if (state.isLoggedIn) {
        const lastLogin = new Date(state.userData.lastLogin)
        const present = new Date()
        const timeDiff = (present.getTime() - lastLogin.getTime()) / 1000 / 60
        if (timeDiff > state.IdleLogoutTime) {
          if (state.appSettings._ENABLE_AUTOLOGOUT) commit('logOutUser')
        } else if (timeDiff > state.IdleLockscreenTime) {
          if (state.appSettings._ENABLE_AUTOLOCK) commit('LockSreeen')
        }
      }
    },
    resetIdleTime({ commit, state }) {
      if (state.isLoggedIn && state.userData) {
        commit('loginUserFromStorage', state.userData)
      }
    },
    checkLogin({ commit, state }) {
      if (!state.isLoggedIn) {
        commit('logOutUser')
      }
    },
    logOutUser({ commit }) {
      commit('showLogoutIndicator')
      commit('logOutUser')
    },
    showIndicator({ commit }) {
      commit('showIndicator')
    },
    hideIndicator({ commit }) {
      commit('hideIndicator')
    },
    showAlert({ commit }, alert) {
      commit('showAlert', alert)
    },
    hideAlert({ commit }, uuid) {
      commit('hideAlert', uuid)
    },
    toggleSidebar({ commit }, sidebarState) {
      commit('toggleSidebar', sidebarState)
    },
    resetIdleWatch({ state, commit, getters }) {
      if (state.isLoggedIn && state.userData) {
        state.userData.lastLogin = new Date()
        localStorage.setItem(
          'lcc-logged-in-user',
          JSON.stringify(state.userData)
        )
      } else {
        getters.isLockSreeen && commit('UnLockSreeen')
      }
    }
  },
  getters: {
    isAuthenticated: (state) => state.isLoggedIn,
    isLoggedIn: (state) => state.isLoggedIn,
    loadingIndicator: (state) => state.loadingIndicator,
    fetching: (state) => {
      const Fetching = {}
      for (let fetching in state.fetching) {
        if (fetching) Fetching[fetching] = true
      }
      return Fetching
    },
    isFetching: (state) => {
      for (let fetching in state.fetching) {
        if (fetching) return true
      }
      return false
    },
    isLockSreeen: (state) =>
      (state.lock === true || localStorage.getItem('lccL') === 'LCCLIENT') &&
      state.isLoggedIn
        ? true
        : false
  },
  modules: {}
})
