<template>
<div v-if="loadingLogoutIndicator" class="loading_logout_spinner_box">
    <div class="loading_spinner"></div>
  </div>
  <div v-if="loadingIndicator" class="loading_spinner_box">
    <div class="loading_spinner"></div>
  </div>
    
  <section class="body">
    <Header v-show="isAuthenticated" />
    <div class="inner-wrapper">
      <SideBar v-show="isAuthenticated" />
      <router-view />
    </div>
  </section>
  <Alerts />
</template>
<script>
import Header from "./components/Header.vue";
import SideBar from "./components/SideBar.vue";
import Alerts from "./components/Alerts.vue";
import { useGetters } from "@/helpers/store";


export default {
  name: "App",
  components: {
    Header,
    SideBar,
    Alerts,
  },
  setup() {
    const { isAuthenticated, loadingIndicator, loadingLogoutIndicator } = useGetters([
      "isAuthenticated",
      "loadingIndicator",
      "loadingLogoutIndicator",
    ]);

    return {
      isAuthenticated,
      loadingIndicator,
      loadingLogoutIndicator,
    };
  },

  mounted() {
    if (typeof localStorage !== "undefined") {
      if (
        localStorage.getItem("skin-admin.css") !== null &&
        !document
          .querySelector("html")
          .hasAttribute("data-style-switcher-options")
      ) {
        var css = localStorage.getItem("skin-admin.css"),
          head = document.head || document.getElementsByTagName("head")[0],
          style = document.createElement("style");

        style.type = "text/css";
        if (style.styleSheet) {
          style.styleSheet.cssText = css;
        } else {
          style.appendChild(document.createTextNode(css));
        }

        head.appendChild(style);
      }

      // Layout
      if (
        localStorage.getItem("layout") !== null &&
        !document
          .querySelector("html")
          .hasAttribute("data-style-switcher-options")
      ) {
        if (localStorage.getItem("layout") == "boxed") {
          var classes = document
            .querySelector("html")
            .className.replace(/fixed/g, "");
          document.querySelector("html").className = classes + " boxed";
        }
      }

      // Background Color
      if (
        localStorage.getItem("backgroundColor") !== null &&
        !document
          .querySelector("html")
          .hasAttribute("data-style-switcher-options")
      ) {
        if (localStorage.getItem("backgroundColor") == "dark") {
          document.querySelector("html").className += " " + "dark";
        }
      }

      // Header Color
      if (
        localStorage.getItem("headerColor") !== null &&
        !document
          .querySelector("html")
          .hasAttribute("data-style-switcher-options")
      ) {
        if (localStorage.getItem("headerColor") == "dark") {
          document.querySelector("html").className += " " + "header-dark";
        }
      }

      // Sidebar Color
      if (
        localStorage.getItem("sidebarColor") !== null &&
        !document
          .querySelector("html")
          .hasAttribute("data-style-switcher-options")
      ) {
        if (localStorage.getItem("sidebarColor") == "light") {
          document.querySelector("html").className += " " + "sidebar-light";
        }
      }

      // Sidebar Size
      if (
        localStorage.getItem("sidebarSize") !== null &&
        !document
          .querySelector("html")
          .hasAttribute("data-style-switcher-options")
      ) {
        var sidebarSizeClass = "";

        switch (localStorage.getItem("sidebarSize")) {
          case "xs":
            sidebarSizeClass = "sidebar-left-xs";
            break;

          case "sm":
            sidebarSizeClass = "sidebar-left-sm";
            break;
        }

        document.querySelector("html").className += " " + sidebarSizeClass;
      }
    }
  },
};
</script>
<style>
@keyframes loading_spinner {
  to {
    transform: rotate(360deg);
  }
}
.loading_logout_spinner_box {
  position: fixed;
  z-index: 111111;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
}
.loading_spinner_box {
  position: fixed;
  z-index: 111111;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
}
.loading_spinner {
  display: block;
  padding: 50px 0;
  /* color: rgb(255, 255, 255); */
}
.loading_spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 40%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin-top: -10px;
  margin-left: -40px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #333;
  animation: loading_spinner 0.6s linear infinite;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

