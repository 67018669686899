<template>
  <div :class="[RedeemBox ? 'w3-block' : '']" class="w3-modal">
    <div class="w3-modal-content w3-animate-top p-5">
      <form id="RedeemForm" method="POST">
        <input type="hidden" :value="uuid()" name="uuid" />
        <input
          type="hidden"
          v-model="Redeem.InvestmentType"
          name="InvestmentType"
        />
        <input type="hidden" v-model="Redeem.ContactNo" name="ContactNo" />
        <div class="row">
          <div class="col-md-6">
            <h3 class="w3-text-red line-height-150">
              You are about to liquidate your investment
            </h3>
          </div>
          <div class="col-md-6">
            <p class="my-0 w3-text-black mb-2">Amount to Liquidate</p>
            <div class="input-group mb-2 max-width250">
              <span v-html="SETTINGS._CURRENCY" class="input-group-text"></span>
              <input
                name="AmountoToRedeem"
                v-model="Redeem.AmountoToRedeem"
                class="form-control form-control-lg"
                @blur="formatOnBlur($event, Redeem)"
                @focus="formatOnFocus($event, Redeem)"
                :readonly="AmountoToRedeem > 0 && Redeem.TokenSent"
              />
            </div>
            <div
              v-if="Redeem.TokenSent && !Redeem.TokenValidated"
              class="max-width250 p-3 w3-border mt-3 w3-sand"
            >
              <label>Enter Verification Token</label>
              <input
                v-model="Redeem.Token"
                @blur="formatOnBlur($event, Redeem)"
                @focus="formatOnFocus($event, Redeem)"
                class="form-control form-control-lg width150"
              />
              <a
                href="javascript:;"
                @click.prevent="SendToken"
                class="p-0 mt-2 w3-block no-hover-underline w3-hover-text-deep-orange"
              >
                <i class="bx bx-refresh"></i> Didn't get Token? Resend
              </a>
            </div>
            <div class="mt-3">
              <button
                v-if="!Redeem.TokenSent"
                @click.prevent="SendToken"
                type="button"
                class="w3-button w3-red w3-hover-orange px-4"
                :disabled="!(AmountoToRedeem > 0) || Redeem.SendingToken"
              >
                <span v-if="Redeem.SendingToken"
                  ><i class="bx bx-refresh bx-spin bx-rotate-180 size18"></i
                ></span>
                <span v-else>Verify</span>
              </button>

              <button
                v-if="Redeem.TokenSent && !Redeem.TokenValidated"
                @click.prevent="VerifyToken"
                type="button"
                class="w3-button w3-red w3-hover-orange px-4"
                :disabled="!Redeem.Token || Redeem.VerifyingToken"
              >
                <span v-if="Redeem.VerifyingToken"
                  ><i class="bx bx-refresh bx-spin bx-rotate-180 size18"></i
                ></span>
                <span v-else>Verify Token</span>
              </button>

              <button
                type="button"
                @click.prevent="CancelRedeemBox"
                class="w3-button w3-light-grey"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <button
    type="button"
    @click.prevent="RedeemBox = true"
    class="w3-button w3-mobile w3-text-white w3-deep-orange w3-hover-orange size14 px-4 py-3 my-2"
  >
    <i class="bx bx-log-in-circle"></i>
    {{ label ?? "LIQUIDATE INVESTMENT" }}
  </button>
</template>
<script>
import {
  formattedCurrency,
  formatOnFocus,
  formatOnBlur,
} from "@/helpers/utilities";
import { useStore } from "vuex";
import { ref, toRef, computed, onBeforeMount } from "vue";
import axios from "axios";
import { v4 as uuid } from "uuid";
export default {
  props: [
    "label",
    "Investmentyype",
    "contactno",
    "email",
    "fullname",
    "phoneno",
    "valuofinvestment",
    "referral",
  ],
  setup(props) {
    const store = useStore();
    const ContactNo = toRef(props, "contactno");
    const EMail = toRef(props, "email");
    const Fullname = toRef(props, "fullname");
    const PhoneNo = toRef(props, "phoneno");

    const InvestmentType = toRef(props, "Investmentyype");
    const ValuOfInvestment = toRef(props, "valuofinvestment");

    const SETTINGS = computed(() => store.state.appSettings);

    const RedeemBox = ref(false);
    const Redeem = ref({
      AmountoToRedeem: 0,
      InvestmentType: InvestmentType.value,
      ContactNo: ContactNo.value,
      Token: "",
      TokenSent: false,
      TokenValidated: false,
      SendingToken: false,
      VerifyingToken: false,
    });
    const AmountoToRedeem = computed(() =>
      Redeem.value.AmountoToRedeem
        ? Redeem.value.AmountoToRedeem.replace(/,/g, "")
        : 0
    );
    const CancelRedeemBox = () => {
      Redeem.value.AmountoToRedeem = 0;
      RedeemBox.value = false;
      Redeem.value.Token = "";
      Redeem.value.TokenSent = false;
      Redeem.value.SendingToken = false;
      Redeem.value.VerifyingToken = false;
      Redeem.value.TokenValidated = false;
    };
    const SendToken = () => {
      if (!(parseFloat(AmountoToRedeem.value) > 0)) {
        store.dispatch("showAlert", {
          message: "Please enter an amount to redeem",
          status: "error",
          showing: true,
        });
        return false;
      }
      if (parseFloat(AmountoToRedeem.value) > ValuOfInvestment.value) {
        store.dispatch("showAlert", {
          message: "Amount to redeem cannot be greater than Fund value",
          status: "error",
          showing: true,
        });
        return false;
      }
      Redeem.value.SendingToken = true;
      Redeem.value.TokenSent = false;
      axios({
        method: "post",
        url: "/api/SendToken",
        data: { EMail: EMail.value, PhoneNo: PhoneNo.value },
      }).then((response) => {
        Redeem.value.SendingToken = false;
        if (response.data.status === "success") {
          Redeem.value.TokenSent = true;
        } else {
          store.dispatch("showAlert", {
            message: response.data.result ?? "An error occured",
            status: "error",
            showing: true,
          });
        }
      });
    };
    const VerifyToken = () => {
      const Token = Redeem.value.Token;
      if (!Token) {
        store.dispatch("showAlert", {
          message: "Please enter Token",
          status: "error",
          showing: true,
        });
        return false;
      }
      Redeem.value.VerifyingToken = true;
      Redeem.value.TokenValidated = false;
      axios({
        method: "post",
        url: "/api/verifyToken",
        data: { Token: Token, UserId: ContactNo.value },
      }).then((response) => {
        if (response.data.status === "success") {
          Redeem.value.TokenValidated = true;
          Liquidate();
        } else {
          Redeem.value.VerifyingToken = false;
          store.dispatch("showAlert", {
            message: response.data.result
              ? response.data.result
              : "An error occured",
            status: "error",
            showing: true,
          });
        }
      });
    };
    const Liquidate = () => {
      if (!(parseFloat(AmountoToRedeem.value) > 0)) {
        store.dispatch("showAlert", {
          message: "Please enter an amount to redeem",
          status: "error",
          showing: true,
        });
        return false;
      }
      store.dispatch("showIndicator");
      axios({
        method: "post",
        url: "/api/Redeem",
        data: {
          AmountoToRedeem: AmountoToRedeem.value,
          ContactNo: ContactNo.value,
          InvestmentType: Redeem.value.InvestmentType,
        },
      }).then((response) => {
        store.dispatch("hideIndicator");
        if (response.data.status === "success") {
          CancelRedeemBox();
          store.dispatch("getInvestmentBalance");
          store.dispatch("showAlert", {
            title: "Liquidation was successful",
            message: response.data.result ?? "An error occured",
            status: "success",
            showing: true,
          });
        } else {
          store.dispatch("showAlert", {
            title: "",
            message: response.data.result ?? "An error occured",
            status: "error",
            showing: true,
          });
        }
      });
    };
    onBeforeMount(() => {
      store.dispatch("resetIdleTime");
    });
    return {
      formattedCurrency,
      SETTINGS,
      AmountoToRedeem,
      SendToken,
      VerifyToken,
      Liquidate,
      CancelRedeemBox,
      Redeem,
      RedeemBox,
      formatOnFocus,
      formatOnBlur,
      EMail,
      ContactNo,
      Fullname,
      InvestmentType,
      uuid,
    };
  },
};
</script>
