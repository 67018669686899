<template>
  <div id="sidebar-left" class="sidebar-left">
    <div class="sidebar-header">
      <div class="sidebar-title"></div>
      <div
        class="sidebar-toggle d-none d-md-block"
        data-toggle-class="sidebar-left-collapsed"
        data-target="html"
        data-fire-event="sidebar-left-toggle"
      >
        <i class="fas fa-bars size24" aria-label="Toggle sidebar"></i>
      </div>
    </div>

    <div class="nano">
      <div class="nano-content" style="overflow-y: scroll!important;">
        <nav id="menu" class="nav-main" role="navigation">
          <ul class="nav nav-main pl-0">
            <li
              v-for="menu in sidebarMenu"
              :key="menu.name"
              :class="[menu.children.length > 0 ? 'nav-parent' : '']"
            >
              <a v-if="!menu.path" class="nav-link size14" href="#">
                <i
                  v-if="menu.icon"
                  :class="[menu.icon, 'size24']"
                  aria-hidden="true"
                ></i>
                <span>{{ menu.title }}</span>
              </a>
              <router-link
                v-else
                class="nav-link size14"
                :to="menu.name ? { name: menu.name } : ''"
              >
                <i
                  v-if="menu.icon"
                  :class="[menu.icon, 'size24']"
                  aria-hidden="true"
                ></i>
                <span>{{ menu.title }}</span>
              </router-link>

              <ul v-if="menu.children.length > 0" class="nav nav-children">
                <li v-for="childmenu in menu.children" :key="childmenu.name">
                  <router-link
                    class="nav-link size14"
                    :to="childmenu.name ? { name: childmenu.name } : ''"
                  >
                    {{ childmenu.title }}
                  </router-link>
                </li>
              </ul>
            </li>
            <li>
              <a href="javascript:;"
                    class="nav-link size14" @click.prevent="logout" 
                ><i class="bx bx-power-off size24 w3-text-red"></i> Log out</a
              >
            </li>
            <li v-if="RM" class="investmentSummary">
          <div
            v-for="rm in RM"
            @click="ShowRM(rm)"
            :key="rm.RmEmail"
            class="size14 mx-3 p-3 cursor w3-hover-border-red overflow-hidden"
          >
            <span class="w3-block bold w3-text-orange">ACCOUNT MANAGER</span>
            <span class="w3-block w3-text-white"><span class="size12 w3-text-orange">NAME:</span> {{ rm.RmName }}</span>
            <span v-if="rm.RmEmail" class="w3-block ellipsis w3-text-white"><span class="size12 w3-text-orange">EMAIL:</span> {{
              rm.RmEmail
            }}</span>
            <span v-if="rm.RmPhoneNo" class="w3-block w3-text-white"><span class="size12 w3-text-orange">TEL:</span> {{ rm.RmPhoneNo }}</span>
          </div>
        </li>


          </ul>
        </nav>
        
      </div>
    </div>
  </div>
</template>
<script>
import Menu from "@/router/menu.js";
import $ from "jquery";
import { useStore } from "vuex";
import { computed } from "vue";
import { ShowRM } from '@/helpers/utilities'

export default {
  name: "SideBar",
  setup() {
    const store = useStore();
    if(!store.state.RM) store.dispatch("RM");
    const RM = computed(() => store.state.RM);
    
    const logout = () => {
      store.dispatch("logOutUser");
    };
    return {
      RM,
      ShowRM,
      logout,
    };
  },
  data() {
    return {
      activeMenu: "dashboard",
      sidebarMenu: Menu,
    };
  },
  mounted: function () {
    // Navigation
    var $items = $(".nav-main li.nav-parent");

    function expand($li) {
      $li.children("ul.nav-children").slideDown("fast", function () {
        $li.addClass("nav-expanded");
        $(this).css("display", "");
        ensureVisible($li);
      });
    }

    function collapse($li) {
      $li.children("ul.nav-children").slideUp("fast", function () {
        $(this).css("display", "");
        $li.removeClass("nav-expanded");
      });
    }

    function ensureVisible($li) {
      var scroller = $li.offsetParent();
      if (!scroller.get(0)) {
        return false;
      }

      var top = $li.position().top;
      if (top < 0) {
        scroller.animate(
          {
            scrollTop: scroller.scrollTop() + top,
          },
          "fast"
        );
      }
    }

    function buildSidebarNav(anchor, prev, next, ev) {
      if (anchor.prop("href")) {
        var arrowWidth =
          parseInt(
            window.getComputedStyle(anchor.get(0), ":after").width,
            10
          ) || 0;
        if (ev.offsetX > anchor.get(0).offsetWidth - arrowWidth) {
          ev.preventDefault();
        }
      }

      if (prev.get(0) !== next.get(0)) {
        collapse(prev);
        expand(next);
      } else {
        collapse(prev);
      }
    }

    $items.find("> a").on("click", function (ev) {
      var $html = $("html"),
        $window = $(window),
        $anchor = $(this),
        $prev = $anchor.closest("ul.nav").find("> li.nav-expanded"),
        $next = $anchor.closest("li"),
        $ev = ev;

      if ($anchor.attr("href") == "#") {
        ev.preventDefault();
      }

      if (!$html.hasClass("sidebar-left-big-icons")) {
        buildSidebarNav($anchor, $prev, $next, $ev);
      } else if (
        $html.hasClass("sidebar-left-big-icons") &&
        $window.width() < 768
      ) {
        buildSidebarNav($anchor, $prev, $next, $ev);
      }
    });

    $(".nav-main").on("touchend", function (e) {
      var $target = $(e.target);

      if ($target.closest("li").hasClass("nav-parent")) {
        setTimeout(function () {
          $("html").removeClass("sidebar-left-collapsed");
          $(".content-body").trigger("click");
        }, 100);
      } else {
        $("html").removeClass("sidebar-left-opened");
      }
    });

    $(".sidebar-toggle").on("click", function () {
      setTimeout(function () {
        if ($("html").hasClass("sidebar-left-collapsed")) {
          localStorage.setItem("sidebar-left-state", "collapsed");
        } else {
          localStorage.setItem("sidebar-left-state", "expanded");
        }
      }, 10);
    });

    if (typeof localStorage !== "undefined") {
      // if (localStorage.getItem("sidebar-left-position") !== null) {
      //   var initialPosition = localStorage.getItem("sidebar-left-position");
      //   var sidebarLeft = document.querySelector("#sidebar-left .nano-content");
      //   sidebarLeft.scrollTop = initialPosition;
      // }
      if (localStorage.getItem("sidebar-left-state")) {
        var sidebarState = localStorage.getItem("sidebar-left-state");
        if (sidebarState === "collapsed") {
          $("html").addClass("sidebar-left-collapsed");
        } else {
          $("html").removeClass("sidebar-left-collapsed");
        }
      } else {
        localStorage.setItem("sidebar-left-state", "collapsed");
      }
    }
    // Chrome Fix
    if ($.browser) {
      $.browser.chrome = /chrom(e|ium)/.test(navigator.userAgent.toLowerCase());
      if ($.browser.chrome && !$.browser.mobile) {
        var flag = true;
        $(".sidebar-left .nav-main li a").on("click", function () {
          flag = false;
          setTimeout(function () {
            flag = true;
          }, 200);
        });

        $(".nano").on("mouseenter", function () {
          $(this).addClass("hovered");
        });

        $(".nano").on("mouseleave", function () {
          if (flag) {
            $(this).removeClass("hovered");
          }
        });
      }
    }

    $(".nav-main a").filter(":not([href])").attr("href", "#");

    // Layout Fixed + Modern - Page Header Bottom Border
    if (
      $("html").hasClass("fixed") &&
      $("html").hasClass("modern") &&
      $(".page-header.page-header-left-inline-breadcrumb").get(0)
    ) {
      $(window).on("scroll", function () {
        if ($(window).scrollTop() > 5) {
          $(".page-header.page-header-left-inline-breadcrumb").addClass(
            "border border-top-0 border-right-0 border-left-0 border-color-light-grey-2"
          );
        } else {
          $(".page-header.page-header-left-inline-breadcrumb").removeClass(
            "border border-top-0 border-right-0 border-left-0 border-color-light-grey-2"
          );
        }
      });
    }

    // Data Hash
    $("[data-hash]").each(function () {
      var target = $(this).attr("href"),
        offset = $(this).is("[data-hash-offset]")
          ? $(this).data("hash-offset")
          : 0;

      if ($(target).get(0)) {
        $(this).on("click", function (e) {
          e.preventDefault();

          if (!$(e.target).is("i")) {
            // Close Collapse if open
            $(this).parents(".collapse.show").collapse("hide");

            $(window).trigger("resize");

            scrollToTarget(target, offset);
          }
        });
      }
    });

    // Scroll To Target
    function scrollToTarget(target, offset) {
      $("body").addClass("scrolling");

      $("html, body").animate(
        {
          scrollTop: $(target).offset().top - offset,
        },
        600,
        function () {
          $("body").removeClass("scrolling");
        }
      );
    }
    // if (typeof localStorage !== "undefined") {
    //   if (localStorage.getItem("sidebar-left-position") !== null) {
    //     var initialPosition = localStorage.getItem("sidebar-left-position"),
    //       sidebarLeft = document.querySelector("#sidebar-left .nano-content");

    //     sidebarLeft.scrollTop = initialPosition;
    //   }
    // }
  },
};
</script>

<style>
.investmentSummaryBox {
  max-width: 100%;
}
.investmentSummary {
  background-color: #8d1d1c;
  background-image: url("/base/img/bg-1.png");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}
.investmentSummary p {
  line-height: normal !important;
}
</style>
